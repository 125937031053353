import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { ProSidebarProvider } from 'react-pro-sidebar';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
    <ProSidebarProvider>
      <App />
      </ProSidebarProvider>
    </Suspense>
  </Provider>
);

reportWebVitals();