import {
    GET_NEWS_LAST_FOUR,
    GET_NEWS_LAST_FOUR_SUCCESS,
    GET_NEWS_LAST_FOUR_ERROR,
    GET_NEWS,
    GET_NEWS_SUCCESS,
    GET_NEWS_ERROR,
    GET_SINGLE_NEWS,
    GET_SINGLE_NEWS_SUCCESS,
    GET_SINGLE_NEWS_ERROR,
    GET_SINGLE_NEWS_CLEAR,
    POST_CONTACT,
    POST_CONTACT_SUCCESS,
    POST_CONTACT_ERROR,
    CLEAR_RESPONSE_MESSAGE,
    GET_BROKER_DETAIL_BY_TABLE,
    GET_BROKER_DETAIL_BY_TABLE_SUCCESS,
    GET_BROKER_DETAIL_BY_TABLE_ERROR,
    GET_DATA_TABLE_TYPE,
    GET_DATA_TABLE_TYPE_SUCCESS,
    GET_DATA_TABLE_TYPE_ERROR,
    GET_DATA_CREDITCARD_TYPE,
    GET_DATA_CREDITCARD_TYPE_SUCCESS,
    GET_DATA_CREDITCARD_TYPE_ERROR,
    GET_DATA_CARD_TYPE,
    GET_DATA_CARD_TYPE_SUCCESS,
    GET_DATA_CARD_TYPE_ERROR,
    GET_DATA_BEST,
    GET_DATA_BEST_SUCCESS,
    GET_DATA_BEST_ERROR,
    GET_SEARCH,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_ERROR,
    GET_SINGLE_DATAS,
    GET_SINGLE_DATAS_SUCCESS,
    GET_SINGLE_DATAS_ERROR,
    CLEAR_SEARCH_HEADER,
    GET_TOP_BROKERS,
    GET_TOP_BROKERS_SUCCESS,
    GET_TOP_BROKERS_ERROR,
} from '../actions';


const INIT_STATE =
{
    loadingGetAll: false,
    error: "",
    loadingNewsLastFour: false,
    newsLastFourData: [],
    loadingNews: false,
    newsData: [],
    loadingSingleNews: false,
    singleNews: {},
    loadingContact: false,
    responseMessage: "",
    loadingBrokerByLoading: false,
    brokerByTableData: [],
    loadingDataTableType: false,
    dataTableType: [],
    loadingDataCreditCardType: false,
    dataCreditCardType: [],
    loadingDataCardType: false,
    dataCardType: [],
    loadingDataBest: false,
    dataBest: {},
    searchData: [],
    totalItem: 0,
    searchText: "",
    whichPage: "",
    loadingSingleDatas: false,
    dataSingleDatas: {},
    loadingTopBrokers: false,
    dataTopBrokers: {}
};

export default (state = INIT_STATE, action: any) => {

    switch (action.type) {
        case GET_NEWS:
            return { ...state, loadingNews: true, newsData: [] };
        case GET_NEWS_SUCCESS:
            return { ...state, loadingNews: false, newsData: action.payload.newsData };
        case GET_NEWS_ERROR:
            return { ...state, loadingNews: false, error: action.payload.message };
        case GET_NEWS_LAST_FOUR:
            return { ...state, loadingNewsLastFour: true, newsLastFourData: [] };
        case GET_NEWS_LAST_FOUR_SUCCESS:
            return { ...state, loadingNewsLastFour: false, newsLastFourData: action.payload.newsLastFourData };
        case GET_NEWS_LAST_FOUR_ERROR:
            return { ...state, loadingNewsLastFour: false, error: action.payload.message };
        case GET_SINGLE_NEWS:
            return { ...state, loadingSingleNews: true, singleNews: {} };
        case GET_SINGLE_NEWS_SUCCESS:
            return { ...state, loadingSingleNews: false, singleNews: action.payload.singleNews };
        case GET_SINGLE_NEWS_ERROR:
            return { ...state, loadingSingleNews: false, error: action.payload.message };
        case GET_SINGLE_NEWS_CLEAR:
            return { ...state, loadingSingleNews: true, singleNews: {} };
        case POST_CONTACT:
            return { ...state, loadingContact: true };
        case POST_CONTACT_SUCCESS:
            return { ...state, loadingContact: false, responseMessage: action.payload.responseMessage };
        case POST_CONTACT_ERROR:
            return { ...state, loadingContact: false, responseMessage: action.payload.message };
        case CLEAR_RESPONSE_MESSAGE:
            return { ...state, responseMessage: '' };
        case GET_BROKER_DETAIL_BY_TABLE:
            return { ...state, loadingBrokerByLoading: true };
        case GET_BROKER_DETAIL_BY_TABLE_SUCCESS:
            return { ...state, loadingBrokerByLoading: false, brokerByTableData: action.payload.brokerByTableData };
        case GET_BROKER_DETAIL_BY_TABLE_ERROR:
            return { ...state, loadingBrokerByLoading: false, error: action.payload.message };
        case GET_DATA_TABLE_TYPE:
            return { ...state, loadingDataTableType: true };
        case GET_DATA_TABLE_TYPE_SUCCESS:
            return { ...state, loadingDataTableType: false, dataTableType: action.payload.finalResponseData };
        case GET_DATA_TABLE_TYPE_ERROR:
            return { ...state, loadingDataTableType: false, error: action.payload.message };
        case GET_DATA_CREDITCARD_TYPE:
            return { ...state, loadingDataCreditCardType: true };
        case GET_DATA_CREDITCARD_TYPE_SUCCESS:
            return { ...state, loadingDataCreditCardType: false, dataCreditCardType: action.payload.finalResponseData };
        case GET_DATA_CREDITCARD_TYPE_ERROR:
            return { ...state, loadingDataCreditCardType: false, error: action.payload.message };
        case GET_DATA_CARD_TYPE:
            return { ...state, loadingDataCardType: true };
        case GET_DATA_CARD_TYPE_SUCCESS:
            return { ...state, loadingDataCardType: false, dataCardType: action.payload.finalResponseData };
        case GET_DATA_CARD_TYPE_ERROR:
            return { ...state, loadingDataCardType: false, error: action.payload.message };
        case GET_DATA_BEST:
            return { ...state, loadingDataBest: true };
        case GET_DATA_BEST_SUCCESS:
            return { ...state, loadingDataBest: false, dataBest: action.payload.finalResponseData };
        case GET_DATA_BEST_ERROR:
            return { ...state, loadingDataBest: false, error: action.payload.message };
        case GET_SEARCH:
            return { ...state, loadingSearch: true, searchData: [] };
        case GET_SEARCH_SUCCESS:
            return { ...state, loadingSearch: false, searchData: action.payload.searchData, totalItem: action.payload.totalItem, searchText: action.payload.searchText, whichPage: action.payload.whichPage };
        case GET_SEARCH_ERROR:
            return { ...state, loadingSearch: false, error: action.payload.message };
        case GET_SINGLE_DATAS:
            return { ...state, loadingSingleDatas: true };
        case GET_SINGLE_DATAS_SUCCESS:
            return { ...state, loadingSingleDatas: false, dataSingleDatas: action.payload.finalResponseData };
        case GET_SINGLE_DATAS_ERROR:
            return { ...state, loadingSingleDatas: false, error: action.payload.message };
        case CLEAR_SEARCH_HEADER:
            return { ...state, searchData: [], totalItem: "", searchText: "", whichPage: "" };
        case GET_TOP_BROKERS:
            return { ...state, loadingTopBrokers: true };
        case GET_TOP_BROKERS_SUCCESS:
            return { ...state, loadingTopBrokers: false, dataTopBrokers: action.payload.finalResponseData };
        case GET_TOP_BROKERS_ERROR:
            return { ...state, loadingTopBrokers: false, error: action.payload.message };
        default:
            return { ...state };
    }
};