export const GET_NEWS_LAST_FOUR = "GET_NEWS_LAST_FOUR";
export const GET_NEWS_LAST_FOUR_SUCCESS = "GET_NEWS_LAST_FOUR_SUCCESS";
export const GET_NEWS_LAST_FOUR_ERROR = "GET_NEWS_LAST_FOUR_ERROR";

export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_ERROR = "GET_NEWS_ERROR";

export const GET_SINGLE_NEWS = "GET_SINGLE_NEWS";
export const GET_SINGLE_NEWS_SUCCESS = "GET_SINGLE_NEWS_SUCCESS";
export const GET_SINGLE_NEWS_ERROR = "GET_SINGLE_NEWS_ERROR";

export const GET_SINGLE_NEWS_CLEAR = "GET_SINGLE_NEWS_CLEAR";

export const POST_CONTACT = "POST_CONTACT";
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";
export const POST_CONTACT_ERROR = "POST_CONTACT_ERROR";

export const CLEAR_RESPONSE_MESSAGE = "CLEAR_RESPONSE_MESSAGE";

export const GET_BROKER_DETAIL_BY_TABLE = "GET_BROKER_DETAIL_BY_TABLE";
export const GET_BROKER_DETAIL_BY_TABLE_SUCCESS = "GET_BROKER_DETAIL_BY_TABLE_SUCCESS";
export const GET_BROKER_DETAIL_BY_TABLE_ERROR = "GET_BROKER_DETAIL_BY_TABLE_ERROR";

export const GET_DATA_TABLE_TYPE = "GET_DATA_TABLE_TYPE";
export const GET_DATA_TABLE_TYPE_SUCCESS = "GET_DATA_TABLE_TYPE_SUCCESS";
export const GET_DATA_TABLE_TYPE_ERROR = "GET_DATA_TABLE_TYPE_ERROR";

export const GET_DATA_CREDITCARD_TYPE = "GET_DATA_CREDITCARD_TYPE";
export const GET_DATA_CREDITCARD_TYPE_SUCCESS = "GET_DATA_CREDITCARD_TYPE_SUCCESS";
export const GET_DATA_CREDITCARD_TYPE_ERROR = "GET_DATA_CREDITCARD_TYPE_ERROR";

export const GET_DATA_CARD_TYPE = "GET_DATA_CARD_TYPE";
export const GET_DATA_CARD_TYPE_SUCCESS = "GET_DATA_CARD_TYPE_SUCCESS";
export const GET_DATA_CARD_TYPE_ERROR = "GET_DATA_CARD_TYPE_ERROR";

export const GET_DATA_BEST = "GET_DATA_BEST";
export const GET_DATA_BEST_SUCCESS = "GET_DATA_BEST_SUCCESS";
export const GET_DATA_BEST_ERROR = "GET_DATA_BEST_ERROR";

export const GET_SEARCH = "GET_SEARCH";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_SEARCH_ERROR = "GET_SEARCH_ERROR";

export const GET_SINGLE_DATAS = "GET_SINGLE_DATAS";
export const GET_SINGLE_DATAS_SUCCESS = "GET_SINGLE_DATAS_SUCCESS";
export const GET_SINGLE_DATAS_ERROR = "GET_SINGLE_DATAS_ERROR";

export const GET_TOP_BROKERS = "GET_TOP_BROKERS";
export const GET_TOP_BROKERS_SUCCESS = "GET_TOP_BROKERS_SUCCESS";
export const GET_TOP_BROKERS_ERROR = "GET_TOP_BROKERS_ERROR";

export const CLEAR_SEARCH_HEADER = "CLEAR_SEARCH_HEADER";


export * from './all/action';