import {
    GET_NEWS_LAST_FOUR,
    GET_NEWS_LAST_FOUR_SUCCESS,
    GET_NEWS_LAST_FOUR_ERROR,
    GET_NEWS,
    GET_NEWS_SUCCESS,
    GET_NEWS_ERROR,
    GET_SINGLE_NEWS,
    GET_SINGLE_NEWS_SUCCESS,
    GET_SINGLE_NEWS_ERROR,
    GET_SINGLE_NEWS_CLEAR ,
    POST_CONTACT,
    POST_CONTACT_SUCCESS,
    POST_CONTACT_ERROR,
    CLEAR_RESPONSE_MESSAGE,
    GET_BROKER_DETAIL_BY_TABLE,
    GET_BROKER_DETAIL_BY_TABLE_SUCCESS,
    GET_BROKER_DETAIL_BY_TABLE_ERROR,
    GET_DATA_TABLE_TYPE,
    GET_DATA_TABLE_TYPE_SUCCESS,
    GET_DATA_TABLE_TYPE_ERROR,
    GET_DATA_CREDITCARD_TYPE,
    GET_DATA_CREDITCARD_TYPE_SUCCESS,
    GET_DATA_CREDITCARD_TYPE_ERROR,
    GET_DATA_CARD_TYPE,
    GET_DATA_CARD_TYPE_SUCCESS,
    GET_DATA_CARD_TYPE_ERROR,
    GET_DATA_BEST,
    GET_DATA_BEST_SUCCESS,
    GET_DATA_BEST_ERROR,
    GET_SEARCH,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_ERROR,
    GET_SINGLE_DATAS,
    GET_SINGLE_DATAS_SUCCESS,
    GET_SINGLE_DATAS_ERROR,
    CLEAR_SEARCH_HEADER,
    GET_TOP_BROKERS,
    GET_TOP_BROKERS_SUCCESS,
    GET_TOP_BROKERS_ERROR,
} from '../actions';

export const getNewsLastFour = (payload: any) => ({
    type: GET_NEWS_LAST_FOUR,
    payload: payload,
});

export const getNewsLastFourError = (message: string) => ({
    type: GET_NEWS_LAST_FOUR_ERROR,
    payload: { message }
});

export const getNewsLastFourSuccess = (payload: any) => ({
    type: GET_NEWS_LAST_FOUR_SUCCESS,
    payload: payload,
});

export const getNews = (payload: any) => ({
    type: GET_NEWS,
    payload: payload,
});

export const getNewsError = (message: string) => ({
    type: GET_NEWS_ERROR,
    payload: { message }
});

export const getNewsSuccess = (payload: any) => ({
    type: GET_NEWS_SUCCESS,
    payload: payload,
});

export const getSingleNews = (payload: any) => ({
    type: GET_SINGLE_NEWS,
    payload: payload,
});

export const getSingleNewsError = (message: string) => ({
    type: GET_SINGLE_NEWS_ERROR,
    payload: { message }
});

export const getSingleNewsSuccess = (payload: any) => ({
    type: GET_SINGLE_NEWS_SUCCESS,
    payload: payload,
});

export const clearSingleNews = () => ({
    type: GET_SINGLE_NEWS_CLEAR,
});

export const postContact = (payload: any) => ({
    type: POST_CONTACT,
    payload: payload,
});

export const postContactError = (message: string) => ({
    type: POST_CONTACT_ERROR,
    payload: { message }
});

export const postContactSuccess = (payload: any) => ({
    type: POST_CONTACT_SUCCESS,
    payload: payload,
});

export const clearResponseMessage = () => ({
    type: CLEAR_RESPONSE_MESSAGE,
});

export const getBrokerDetailByTable = (payload: any) => ({
    type: GET_BROKER_DETAIL_BY_TABLE,
    payload: payload,
});

export const getBrokerDetailByTableError = (message: string) => ({
    type: GET_BROKER_DETAIL_BY_TABLE_ERROR,
    payload: { message }
});

export const getBrokerDetailByTableSuccess = (payload: any) => ({
    type: GET_BROKER_DETAIL_BY_TABLE_SUCCESS,
    payload: payload,
});

export const getDataTableType = (payload: any) => ({
    type: GET_DATA_TABLE_TYPE,
    payload: payload,
});

export const getDataTableTypeError = (message: string) => ({
    type: GET_DATA_TABLE_TYPE_ERROR,
    payload: { message }
});

export const getDataTableTypeSuccess = (payload: any) => ({
    type: GET_DATA_TABLE_TYPE_SUCCESS,
    payload: payload,
});

export const getDataCreditCardType = (payload: any) => ({
    type: GET_DATA_CREDITCARD_TYPE,
    payload: payload,
});

export const getDataCreditCardTypeError = (message: string) => ({
    type: GET_DATA_CREDITCARD_TYPE_ERROR,
    payload: { message }
});

export const getDataCreditCardTypeSuccess = (payload: any) => ({
    type: GET_DATA_CREDITCARD_TYPE_SUCCESS,
    payload: payload,
});

export const getDataCardType = (payload: any) => ({
    type: GET_DATA_CARD_TYPE,
    payload: payload,
});

export const getDataCardTypeError = (message: string) => ({
    type: GET_DATA_CARD_TYPE_ERROR,
    payload: { message }
});

export const getDataCardTypeSuccess = (payload: any) => ({
    type: GET_DATA_CARD_TYPE_SUCCESS,
    payload: payload,
});

export const getDataBest = (payload: any) => ({
    type: GET_DATA_BEST,
    payload: payload,
});

export const getDataBestError = (message: string) => ({
    type: GET_DATA_BEST_ERROR,
    payload: { message }
});

export const getDataBestSuccess = (payload: any) => ({
    type: GET_DATA_BEST_SUCCESS,
    payload: payload,
});

export const getSearch = (payload: any) => ({
    type: GET_SEARCH,
    payload: payload,
});
  
export const getSearchError = (message : string) => ({
    type: GET_SEARCH_ERROR,
    payload: { message }
});

export const getSearchSuccess = (payload : any) => ({
    type: GET_SEARCH_SUCCESS,
    payload: payload,
});

export const getSingleDatas = (payload: any) => ({
    type: GET_SINGLE_DATAS,
    payload: payload,
});
  
export const getSingleDatasError = (message : string) => ({
    type: GET_SINGLE_DATAS_ERROR,
    payload: { message }
});

export const getSingleDatasSuccess = (payload : any) => ({
    type: GET_SINGLE_DATAS_SUCCESS,
    payload: payload,
});

export const clearSearchHeader = () => ({
    type: CLEAR_SEARCH_HEADER,
});

export const getTopBrokers = (payload: any) => ({
    type: GET_TOP_BROKERS,
    payload: payload,
});

export const getTopBrokersError = (message: string) => ({
    type: GET_TOP_BROKERS_ERROR,
    payload: { message }
});

export const getTopBrokersSuccess = (payload: any) => ({
    type: GET_TOP_BROKERS_SUCCESS,
    payload: payload,
});
