import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    GET_NEWS_LAST_FOUR,
    GET_NEWS,
    GET_SINGLE_NEWS,
    POST_CONTACT,
    GET_BROKER_DETAIL_BY_TABLE,
    GET_DATA_TABLE_TYPE,
    GET_DATA_CREDITCARD_TYPE,
    GET_DATA_CARD_TYPE,
    GET_DATA_BEST,
    GET_SEARCH,
    GET_SINGLE_DATAS,
    GET_TOP_BROKERS
} from '../actions';

import {
    getNewsLastFourSuccess,
    getNewsLastFourError,
    getNewsSuccess,
    getNewsError,
    getSingleNewsSuccess,
    getSingleNewsError,
    postContactSuccess,
    postContactError,
    getBrokerDetailByTableSuccess,
    getBrokerDetailByTableError,
    getDataTableTypeSuccess,
    getDataTableTypeError,
    getDataCreditCardTypeSuccess,
    getDataCreditCardTypeError,
    getDataCardTypeSuccess,
    getDataCardTypeError,
    getDataBestSuccess,
    getDataBestError,
    getSearchSuccess,
    getSearchError,
    getSingleDatasSuccess,
    getSingleDatasError,
    getTopBrokersSuccess,
    getTopBrokersError
} from './action';

import {
    baseUrl,
    getHeader,
    getNewsLastFourUrl,
    getNewsUrl,
    getSingleNewsUrl,
    postContactUrl,
    getBrokerDetailByTableUrl,
    getDataTableTypeUrl,
    getDataCreditCardTypeUrl,
    getDataCardTypeUrl,
    getDataBestUrl,
    getSearchUrl,
    getSingleDatasUrl,
    getTopBrokersUrl,
    getMethod,
    postMethod
} from '../../constant/Services';


//Get news last four
export function* watchGetNewsLastFour() {
    yield takeEvery(GET_NEWS_LAST_FOUR, getNewsLastFour);
}


const getNewsLastFourAsync = async (payload: any) => {

    const { source, id } = payload;
    let response;

    await getMethod(getNewsLastFourUrl(source, id), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getNewsLastFour({ payload }: any): any {

    try {

        let responseData = yield call(getNewsLastFourAsync, payload);

        if (responseData.status === 200) {

            const articlesList: any = [];
            responseData.data.data.forEach((item: any) => {

                articlesList.push({
                    id: item.id,
                    title: item.attributes.title,
                    slug: item.attributes.slug,
                    excerpt: item.attributes.excerpt,
                    image: item.attributes.media.data && item.attributes.media.data.attributes.formats.small ? baseUrl + item.attributes.media.data.attributes.formats.small.url : item.attributes.media.data && item.attributes.media.data.attributes.formats.thumbnail ? baseUrl + item.attributes.media.data.attributes.formats.thumbnail.url : ""
                })
            });

            const value = { newsLastFourData: articlesList }

            yield put(getNewsLastFourSuccess(value));

        } else {
            yield put(getNewsLastFourError("error"));
        }
    } catch (error) {
        yield put(getNewsLastFourError("error"));
    }
}

//Get news all
export function* watchGetNews() {
    yield takeEvery(GET_NEWS, getNews);
}


const getNewsAsync = async (payload: any) => {

    const { source, countryCode } = payload;
    let response;

    await getMethod(getNewsUrl(source, countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getNews({ payload }: any): any {

    try {

        let responseData = yield call(getNewsAsync, payload);

        if (responseData.status === 200) {

            const articlesList: any = [];
            responseData.data.data.forEach((item: any) => {

                articlesList.push({
                    id: item.id,
                    title: item.attributes.title,
                    slug: item.attributes.slug,
                    excerpt: item.attributes.excerpt,
                    image: item.attributes.media.data && item.attributes.media.data.attributes.formats.small ? baseUrl + item.attributes.media.data.attributes.formats.small.url : item.attributes.media.data && item.attributes.media.data.attributes.formats.thumbnail ? baseUrl + item.attributes.media.data.attributes.formats.thumbnail.url : ""
                })
            });

            const value = { newsData: articlesList }

            yield put(getNewsSuccess(value));

        } else {
            yield put(getNewsError("error"));
        }
    } catch (error) {
        yield put(getNewsError("error"));
    }
}



//GET SINGLE NEWS

export function* watchGetSingleNews() {
    yield takeEvery(GET_SINGLE_NEWS, getSingleNews);
}

const getSingleNewsAsync = async (payload: any) => {

    const { id, countryCode } = payload;
    let response;

    await getMethod(getSingleNewsUrl(id, countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}

function* getSingleNews({ payload }: any): any {

    try {

        let responseData = yield call(getSingleNewsAsync, payload);

        if (responseData.status === 200) {

            const articleSingleInfo = {
                id: responseData.data.data.id,
                title: responseData.data.data.attributes.title,
                slug: responseData.data.data.attributes.slug,
                page: responseData.data.data.attributes.page,
                content: responseData.data.data.attributes.content,
                image: responseData.data.data.attributes.media.data && responseData.data.data.attributes.media.data.attributes.formats.large ? baseUrl + responseData.data.data.attributes.media.data.attributes.formats.large.url : responseData.data.data.attributes.media.data && responseData.data.data.attributes.media.data.attributes.formats.medium ? baseUrl + responseData.data.data.attributes.media.data.attributes.formats.medium.url : responseData.data.data.attributes.media.data && responseData.data.data.attributes.media.data.attributes.formats.small ? baseUrl + responseData.data.data.attributes.media.data.attributes.formats.small.url : responseData.data.data.attributes.media.data && responseData.data.data.attributes.media.data.attributes.formats.thumbnail ? baseUrl + responseData.data.data.attributes.media.data.attributes.formats.thumbnail.url : ""
            }

            const value = { singleNews: articleSingleInfo }
            yield put(getSingleNewsSuccess(value));

        } else {
            yield put(getSingleNewsError("error"));
        }
    } catch (error) {
        yield put(getSingleNewsError("error"));
    }
}


// POST CONTACT

export function* watchPostContact() {
    yield takeEvery(POST_CONTACT, postContact);
}


const postContactAsync = async (payload: any) => {

    const { name, email, subject, message } = payload;
    let response;

    const req = {
        "data": {
            "name": name,
            "email": email,
            "subject": subject,
            "message": message
        }
    }

    await postMethod(postContactUrl(), req, getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* postContact({ payload }: any): any {

    try {

        let responseData = yield call(postContactAsync, payload);

        if (responseData.status === 200) {
            const value = { responseMessage: "POST_SUCCESS" }
            yield put(postContactSuccess(value));

        } else {
            yield put(postContactError("error"));
        }
    } catch (error) {
        yield put(postContactError("error"));
    }
}


// GET BROKER DETAIL BY TABLE

export function* watchGetBrokerDetailByTable() {
    yield takeEvery(GET_BROKER_DETAIL_BY_TABLE, getBrokerDetailByTable);
}


const getBrokerDetailByTableAsync = async (payload: any) => {
    const { url } = payload;
    let response;

    await getMethod(getBrokerDetailByTableUrl(url), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getBrokerDetailByTable({ payload }: any): any {

    try {

        let responseData = yield call(getBrokerDetailByTableAsync, payload);

        if (responseData.status === 200) {

            const commentList: any = [];
            responseData.data.data.forEach((item: any) => {

                commentList.push({
                    id: item.id,
                    name: item.attributes.name,
                    description: item.attributes.description
                })
            });

            const value = { brokerByTableData: commentList }
            yield put(getBrokerDetailByTableSuccess(value));

        } else {
            yield put(getBrokerDetailByTableError("error"));
        }
    } catch (error) {
        yield put(getBrokerDetailByTableError("error"));
    }
}


// GET Data Table Type

export function* watchGetDataTableType() {
    yield takeEvery(GET_DATA_TABLE_TYPE, getDataTableType);
}


const getDataTableTypeAsync = async (payload: any) => {

    const { url, countryCode } = payload;

    let response;

    await getMethod(getDataTableTypeUrl(url, countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getDataTableType({ payload }: any): any {

    try {

        let responseData = yield call(getDataTableTypeAsync, payload);

        if (responseData.status === 200) {

            const newResponseData: any = [];
            responseData.data.data.forEach((item: any) => {
                if (payload?.url === "stock-brokers") {
                    newResponseData.push({
                        id: item.id,
                        slug: item.attributes.slug,
                        first: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        second: item.attributes.rating,
                        third: {
                            amount: item.attributes.fees,
                            description: item.attributes.fees_description
                        },
                        fourth: item.attributes.account_minimum,
                        fifth: {
                            text: item.attributes.promotion,
                            description: item.attributes.promotion_description
                        },
                        sixth: item.attributes.link
                    })
                } else if (payload?.url === "car-insurance" || payload?.url === "health-insurance" || payload?.url === "life-insurance" || payload?.url === "home-insurance" || payload?.url === "pet-insurance") {
                    newResponseData.push({
                        id: item.id,
                        first: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        second: item.attributes.rating,
                        third: {
                            amount: item.attributes.coverage_area,
                            description: ""
                        },
                        fourth: item.attributes.no_of_providers,
                        fifth: {
                            text: item.attributes.physician_start_at,
                            description: ""
                        },
                        sixth: item.attributes.link
                    })
                } else if (payload?.url === "saving-account" || payload?.url === "checking-account") {
                    newResponseData.push({
                        id: item.id,
                        first: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        second: item.attributes.rating,
                        third: {
                            amount: item.attributes.apy,
                            description: item.attributes.apy_description
                        },
                        fifth: {
                            text: item.attributes.bonus,
                            description: item.attributes.bonus_description
                        },
                        sixth: item.attributes.link
                    })
                } else if (payload?.url === "mortgage" || payload?.url === "personal-loans" || payload?.url === "small-business" || payload?.url === "auto" || payload?.url === "student-loans") {
                    newResponseData.push({
                        id: item.id,
                        first: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        second: item.attributes.rating,
                        third: {
                            amount: item.attributes.min_credit_score,
                            description: ""
                        },
                        fourth: item.attributes.fixed_apr,
                        fifth: {
                            text: item.attributes.variable_apr,
                            description: ""
                        },
                        sixth: item.attributes.link
                    })
                }
            });

            const value = { finalResponseData: newResponseData }
            yield put(getDataTableTypeSuccess(value));

        } else {
            yield put(getDataTableTypeError("error"));
        }
    } catch (error) {
        yield put(getDataTableTypeError("error"));
    }
}


// GET Data CreditCard Type

export function* watchGetDataCreditCardType() {
    yield takeEvery(GET_DATA_CREDITCARD_TYPE, getDataCreditCardType);
}


const getDataCreditCardTypeAsync = async (payload: any) => {

    const { url, countryCode } = payload;
    let response;

    await getMethod(getDataCreditCardTypeUrl(url, countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getDataCreditCardType({ payload }: any): any {

    try {

        let responseData = yield call(getDataCreditCardTypeAsync, payload);

        if (responseData.status === 200) {

            const newResponseData: any = [];
            responseData.data.data.forEach((item: any) => {
                if (payload?.url === "rewards" || payload?.url === "travel" || payload?.url === "business" || payload?.url === "balance-transfer" || payload?.url === "cash-back" || payload?.url === "travel-cards" || payload?.url === "hotels-cards" || payload?.url === "student-credit-cards") {
                    newResponseData.push({
                        id: item.id,
                        title: item.attributes.name,
                        image: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        rating: item.attributes.rating,
                        annualFee: {
                            amount: item.attributes.annual_fee
                        },
                        rewardsRate: {
                            amount: item.attributes.rewards_rate,
                            description: item.attributes.rewards_rate_description
                        },
                        IntroOffer: {
                            amount: item.attributes.intro_offer,
                            description: item.attributes.intro_offer_description
                        },
                        Recommended: {
                            amount: item.attributes.recommended_credit_score
                        },
                        link: item.attributes.link,
                        details: [
                            {
                                key: 1,
                                isOpen: false,
                                title: "Product details",
                                content: item.attributes.product_details
                            },
                            {
                                key: 2,
                                isOpen: false,
                                title: "Pros & Cons",
                                content: item.attributes.pros_cons
                            }
                        ]
                    })
                } else if (payload?.url === "forex-brokers" || payload?.url === "crypto-brokers") {

                    newResponseData.push({
                        id: item.id,
                        slug: item.attributes.slug,
                        title: item.attributes.name,
                        image: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        rating: item.attributes.rating,
                        annualFee: {
                            amount: item.attributes.initial_deposit
                        },
                        rewardsRate: {
                            amount: item.attributes.regulation,
                            description: ""
                        },
                        IntroOffer: {
                            amount: item.attributes.leverage,
                            description: ""
                        },
                        Recommended: {
                            amount: item.attributes.funding_methods
                        },
                        link: item.attributes.link,
                        best: item?.attributes?.best,
                        review: item?.attributes?.review,
                        details: [
                            {
                                key: 1,
                                isOpen: false,
                                title: "Product details",
                                content: item.attributes.product_details
                            },
                            {
                                key: 2,
                                isOpen: false,
                                title: "Pros & Cons",
                                content: item.attributes.pros_cons
                            }
                        ]
                    })
                }
            });

            const value = { finalResponseData: newResponseData }
            yield put(getDataCreditCardTypeSuccess(value));

        } else {
            yield put(getDataCreditCardTypeError("error"));
        }
    } catch (error) {
        yield put(getDataCreditCardTypeError("error"));
    }
}


// GET Data Card Type

export function* watchGetDataCardType() {
    yield takeEvery(GET_DATA_CARD_TYPE, getDataCardType);
}


const getDataCardTypeAsync = async (payload: any) => {

    const { url, countryCode } = payload;
    let response;

    await getMethod(getDataCardTypeUrl(url, countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getDataCardType({ payload }: any): any {

    try {

        let responseData = yield call(getDataCardTypeAsync, payload);

        if (responseData.status === 200) {

            const newResponseData: any = [];
            responseData.data.data.forEach((item: any) => {
                if (payload?.url === "airlines" || payload?.url === "private-jets" || payload?.url === "travel-sites" || payload?.url === "investing" || payload?.url === "real-estate" || payload?.url === "books" || payload?.url === "tax-preparation" || payload?.url === "gold") {
                    newResponseData.push({
                        id: item.id,
                        logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        description: item.attributes.description,
                        link: item.attributes.link
                    })
                }
            });

            const value = { finalResponseData: newResponseData }
            yield put(getDataCardTypeSuccess(value));

        } else {
            yield put(getDataCardTypeError("error"));
        }
    } catch (error) {
        yield put(getDataCardTypeError("error"));
    }
}



// GET Data Best

export function* watchGetDataBest() {
    yield takeEvery(GET_DATA_BEST, getDataBest);
}


const getDataBestAsync = async (payload: any) => {

    let response;

    const { countryCode } = payload;

    await getMethod(getDataBestUrl(countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getDataBest({ payload }: any): any {

    try {

        let responseData = yield call(getDataBestAsync, payload);

        if (responseData.status === 200) {

            const newResponseData: any = {
                creditCards: [],
                stockBrokers: [],
                forexBrokers: [],
                insuranceCompany: [],
                savingBanks: []
            };

            responseData.data.data.forEach((item: any) => {
                if (item.attributes.section === "Credit Cards") {
                    newResponseData.creditCards.push({
                        id: item.id,
                        logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        link: item.attributes.link
                    })
                } else if (item.attributes.section === "Stock Brokers") {
                    newResponseData.stockBrokers.push({
                        id: item.id,
                        logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        link: item.attributes.link
                    })
                } else if (item.attributes.section === "Forex Brokers") {
                    newResponseData.forexBrokers.push({
                        id: item.id,
                        logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        link: item.attributes.link
                    })
                } else if (item.attributes.section === "Insurance Company") {
                    newResponseData.insuranceCompany.push({
                        id: item.id,
                        logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        link: item.attributes.link
                    })
                } else if (item.attributes.section === "Saving Banks") {
                    newResponseData.savingBanks.push({
                        id: item.id,
                        logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        link: item.attributes.link
                    })
                }
            });

            const value = { finalResponseData: newResponseData }
            yield put(getDataBestSuccess(value));

        } else {
            yield put(getDataBestError("error"));
        }
    } catch (error) {
        yield put(getDataBestError("error"));
    }
}



// GET Search data

export function* watchGetSearch() {
    yield takeEvery(GET_SEARCH, getSearch);
}


const getSearchAsync = async (payload: any) => {
    const { text, page, countryCode } = payload;

    let response;

    await getMethod(getSearchUrl(text, page, countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getSearch({ payload }: any): any {

    try {

        let responseData = yield call(getSearchAsync, payload);

        if (responseData.status === 200) {

            const newResponseData: any = [];
            responseData.data.data.forEach((item: any) => {

                if (payload?.page === "Stock Brokers") {
                    newResponseData.push({
                        id: item.id,
                        first: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        second: item.attributes.rating,
                        third: {
                            amount: item.attributes.fees,
                            description: item.attributes.fees_description
                        },
                        fourth: item.attributes.account_minimum,
                        fifth: {
                            text: item.attributes.promotion,
                            description: item.attributes.promotion_description
                        },
                        sixth: item.attributes.link
                    })
                } else if (payload?.page === "Car Insurance" || payload?.page === "Health Insurance" || payload?.page === "Life Insurance" || payload?.page === "Home Insurance" || payload?.page === "Pet Insurance") {
                    newResponseData.push({
                        id: item.id,
                        first: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        second: item.attributes.rating,
                        third: {
                            amount: item.attributes.coverage_area,
                            description: ""
                        },
                        fourth: item.attributes.no_of_providers,
                        fifth: {
                            text: item.attributes.physician_start_at,
                            description: ""
                        },
                        sixth: item.attributes.link
                    })
                } else if (payload?.page === "Saving Account" || payload?.page === "Checking Account") {
                    newResponseData.push({
                        id: item.id,
                        first: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        second: item.attributes.rating,
                        third: {
                            amount: item.attributes.apy,
                            description: item.attributes.apy_description
                        },
                        fifth: {
                            text: item.attributes.bonus,
                            description: item.attributes.bonus_description
                        },
                        sixth: item.attributes.link
                    })
                } else if (payload?.page === "Mortgage" || payload?.page === "Personal Loans" || payload?.page === "Small Business" || payload?.page === "Auto" || payload?.page === "Student Loans") {
                    newResponseData.push({
                        id: item.id,
                        first: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        second: item.attributes.rating,
                        third: {
                            amount: item.attributes.min_credit_score,
                            description: ""
                        },
                        fourth: item.attributes.fixed_apr,
                        fifth: {
                            text: item.attributes.variable_apr,
                            description: ""
                        },
                        sixth: item.attributes.link
                    })
                } else if (payload?.page === "Rewards" || payload?.page === "Travel" || payload?.page === "Business" || payload?.page === "Balance Transfer" || payload?.page === "Cash back" || payload?.page === "Travel cards" || payload?.page === "Hotels cards" || payload?.page === "Student Credit Cards") {
                    newResponseData.push({
                        id: item.id,
                        title: item.attributes.name,
                        image: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        rating: item.attributes.rating,
                        annualFee: {
                            amount: item.attributes.annual_fee
                        },
                        rewardsRate: {
                            amount: item.attributes.rewards_rate,
                            description: item.attributes.rewards_rate_description
                        },
                        IntroOffer: {
                            amount: item.attributes.intro_offer,
                            description: item.attributes.intro_offer_description
                        },
                        Recommended: {
                            amount: item.attributes.recommended_credit_score
                        },
                        link: item.attributes.link,
                        details: [
                            {
                                key: 1,
                                isOpen: false,
                                title: "Product details",
                                content: item.attributes.product_details
                            },
                            {
                                key: 2,
                                isOpen: false,
                                title: "Pros & Cons",
                                content: item.attributes.pros_cons
                            }
                        ]
                    })
                } else if (payload?.page === "Forex Brokers" || payload?.page === "Crypto Brokers") {
                    newResponseData.push({
                        id: item.id,
                        title: item.attributes.name,
                        image: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        rating: item.attributes.rating,
                        annualFee: {
                            amount: item.attributes.initial_deposit
                        },
                        rewardsRate: {
                            amount: item.attributes.regulation,
                            description: ""
                        },
                        IntroOffer: {
                            amount: item.attributes.leverage,
                            description: ""
                        },
                        Recommended: {
                            amount: item.attributes.funding_methods
                        },
                        link: item.attributes.link,
                        details: [
                            {
                                key: 1,
                                isOpen: false,
                                title: "Product details",
                                content: item.attributes.product_details
                            },
                            {
                                key: 2,
                                isOpen: false,
                                title: "Pros & Cons",
                                content: item.attributes.pros_cons
                            }
                        ]
                    })
                } else if (payload?.page === "Airlines" || payload?.page === "Private jets" || payload?.page === "Travel sites" || payload?.page === "Investing" || payload?.page === "Real estate" || payload?.page === "Books" || payload?.page === "Tax preparation" || payload?.page === "Gold") {
                    newResponseData.push({
                        id: item.id,
                        logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                        description: item.attributes.description,
                        link: item.attributes.link
                    })
                }
            });

            const totalItem = responseData.data.meta.pagination.total;

            const value = { searchData: newResponseData, totalItem: totalItem, searchText: payload.text, whichPage: payload.page }
            yield put(getSearchSuccess(value));

        } else {
            yield put(getSearchError("error"));
        }
    } catch (error) {
        yield put(getSearchError("error"));
    }
}


//GET SINGLE DATAS

export function* watchGetSingleDatas() {
    yield takeEvery(GET_SINGLE_DATAS, getSingleDatas);
}

const getSingleDatasAsync = async (payload: any) => {

    const { url, id, countryCode } = payload;
    let response;

    await getMethod(getSingleDatasUrl(url, id, countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}

function* getSingleDatas({ payload }: any): any {

    try {

        let responseData = yield call(getSingleDatasAsync, payload);

        if (responseData.status === 200) {

            const articleSingleInfo = {
                id: responseData.data.data.id,
                name: responseData.data.data.attributes.name,
                rating: responseData.data.data.attributes.rating,
                image: responseData.data.data.attributes.image.data ? baseUrl + responseData.data.data.attributes.image.data.attributes.url : "",
                link: responseData.data.data.attributes.link,
                review: responseData.data.data.attributes?.review,
                review_description: responseData.data.data.attributes.review_description
            }

            const value = { finalResponseData: articleSingleInfo }
            yield put(getSingleDatasSuccess(value));

        } else {
            yield put(getSingleDatasError("error"));
        }
    } catch (error) {
        yield put(getSingleDatasError("error"));
    }
}

//Get top brokers
export function* watchGetTopBrokers() {
    yield takeEvery(GET_TOP_BROKERS, getTopBrokers);
}


const getTopBrokersAsync = async (payload: any, endpoint: any) => {
    const { countryCode } = payload;
    let response;

    await getMethod(getTopBrokersUrl(endpoint, countryCode), getHeader()).then((res: any) => {
        response = res;
    }).catch((error: any) => {
        response = JSON.parse(JSON.stringify(error));
    });
    return await response;
}


function* getTopBrokers({ payload }: any): any {

    try {


        const [responseData, responseData2, responseData3] = yield all([
            call(getTopBrokersAsync, payload, "forex-brokers"),
            call(getTopBrokersAsync, payload, "stock-brokers"),
            call(getTopBrokersAsync, payload, "crypto-brokers")
          ])

        if (responseData.status === 200) {

            const responseList: any = {
                stockBrokers: [],
                forexBrokers: [],
                cryptoBrokers: []
            };

            responseData.data.data.forEach((item: any) => {

                responseList.forexBrokers.push({
                    id: item.id,
                    slug: item.attributes.slug,
                    section: "forexBrokers",
                    logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                    link: item.attributes.link
                })
            });

            responseData2.data.data.forEach((item: any) => {

                responseList.stockBrokers.push({
                    id: item.id,
                    slug: item.attributes.slug,
                    section: "stockBrokers",
                    logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                    link: item.attributes.link
                })
            });

            responseData3.data.data.forEach((item: any) => {

                responseList.cryptoBrokers.push({
                    id: item.id,
                    slug: item.attributes.slug,
                    section: "cryptoBrokers",
                    logo: item.attributes.image.data ? baseUrl + item.attributes.image.data.attributes.url : "",
                    link: item.attributes.link
                })
            });

            const value = { finalResponseData: responseList }

            yield put(getTopBrokersSuccess(value));

        } else {
            yield put(getTopBrokersError("error"));
        }
    } catch (error) {
        yield put(getTopBrokersError("error"));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetNewsLastFour),
        fork(watchGetNews),
        fork(watchGetSingleNews),
        fork(watchPostContact),
        fork(watchGetBrokerDetailByTable),
        fork(watchGetDataTableType),
        fork(watchGetDataCreditCardType),
        fork(watchGetDataCardType),
        fork(watchGetDataBest),
        fork(watchGetSearch),
        fork(watchGetSingleDatas),
        fork(watchGetTopBrokers)
    ]);
}