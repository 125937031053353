import axios from "axios";

export const getHeader = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
        }
    }
}

export const locationURL = "https://ipinfo.io/json?token=d69752f736bf35";
// export const locationURL = "https://ipinfo.io/json?token=476d59a65b95bb";
// export const baseUrl = "http://localhost:1337";
// const deployUrl = "http://localhost:1337/api";
export const baseUrl = "https://backend.gidinvesting.com";
const deployUrl = "https://backend.gidinvesting.com/api";


export const getNewsLastFourUrl = (source: any, id: any) => {
    return deployUrl + "/news?filters[page][$eq]=" + source + "&filters[id][$ne]=" + id + "&pagination[page]=1&pagination[pageSize]=4&populate=%2A&sort[0]=createdAt%3Adesc";
}

export const getNewsUrl = (source: any, countryCode: string) => {

    if (source === "newsPage") {
        return deployUrl + "/news?populate=%2A&sort[0]=createdAt%3Adesc";
    } else {
        return deployUrl + "/news?filters[page][$eq]=" + source + "&populate=%2A&sort[0]=createdAt%3Adesc";
    }
}

export const getSingleNewsUrl = (id: number, countryCode: string) => {
    return deployUrl + "/news/" + id + "?populate=%2A";
}

export const postContactUrl = () => {
    return deployUrl + "/contacts";
}

export const getBrokerDetailByTableUrl = (url: string) => {
    return deployUrl + "/company-details?filters[url][$eq]=" + url + "&[fields][0]=name&[fields][1]=description";
}

export const getSingleDatasUrl = (url: string, id: string, countryCode: string) => {
    if (url === "stock-brokers") {
        return deployUrl + "/stock-brokers/" + id + "?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "forex-brokers") {
        return deployUrl + "/forex-brokers/" + id + "?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "crypto-brokers") {
        return deployUrl + "/crypto-brokers/" + id + "?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else {
        return ""
    }
}

export const getDataTableTypeUrl = (url: string, countryCode: string) => {
    if (url === "car-insurance") {
        return deployUrl + "/car-insurances?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "health-insurance") {
        return deployUrl + "/health-insurances?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "life-insurance") {
        return deployUrl + "/life-insurances?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "home-insurance") {
        return deployUrl + "/home-insurances?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "pet-insurance") {
        return deployUrl + "/pet-insurances?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "stock-brokers") {
        return deployUrl + "/stock-brokers?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "saving-account") {
        return deployUrl + "/saving-accounts?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "checking-account") {
        return deployUrl + "/checking-accounts?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "personal-loans") {
        return deployUrl + "/personal-loans?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "mortgage") {
        return deployUrl + "/mortgages?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "small-business") {
        return deployUrl + "/small-businesses?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "auto") {
        return deployUrl + "/autos?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "student-loans") {
        return deployUrl + "/student-loans?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else {
        return ""
    }
}


export const getDataCreditCardTypeUrl = (url: string, countryCode: string) => {
    if (url === "rewards") {
        return deployUrl + "/rewards?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "travel") {
        return deployUrl + "/travels?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "business") {
        return deployUrl + "/businesses?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "balance-transfer") {
        return deployUrl + "/balance-transfers?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "cash-back") {
        return deployUrl + "/shoppings?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "travel-cards") {
        return deployUrl + "/ravel-cards?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "hotels-cards") {
        return deployUrl + "/hotel-cards?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "student-credit-cards") {
        return deployUrl + "/student-credit-cards?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "forex-brokers") {
        return deployUrl + "/forex-brokers?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "crypto-brokers") {
        return deployUrl + "/crypto-brokers?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else {
        return ""
    }
}

export const getDataCardTypeUrl = (url: string, countryCode: string) => {
    if (url === "airlines") {
        return deployUrl + "/airlines?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "private-jets") {
        return deployUrl + "/private-jets?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "travel-sites") {
        return deployUrl + "/travel-sites?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "investing") {
        return deployUrl + "/investings?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "real-estate") {
        return deployUrl + "/real-estates?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "books") {
        return deployUrl + "/books?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "tax-preparation") {
        return deployUrl + "/tax-preparations?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (url === "gold") {
        return deployUrl + "/golds?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else {
        return ""
    }
}


export const getDataBestUrl = (countryCode: string) => {
    return deployUrl + "/bests?filters[country][country][$eq]=" + countryCode + "&populate=%2A&sort[0]=createdAt%3Adesc";
}

export const getSearchUrl = (text: string, page: string, countryCode: string) => {
    if (page === "Stock Brokers") {
        return deployUrl + "/stock-brokers?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Car Insurance") {
        return deployUrl + "/car-insurances?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Health Insurance") {
        return deployUrl + "/health-insurances?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Life Insurance") {
        return deployUrl + "/life-insurances?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Home Insurance") {
        return deployUrl + "/home-insurances?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Pet Insurance") {
        return deployUrl + "/pet-insurances?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Saving Account") {
        return deployUrl + "/saving-accounts?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Checking Account") {
        return deployUrl + "/checking-accounts?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Mortgage") {
        return deployUrl + "/mortgages?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Personal Loans") {
        return deployUrl + "/personal-loans?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Small Business") {
        return deployUrl + "/small-businesses?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Auto") {
        return deployUrl + "/autos?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Student Loans") {
        return deployUrl + "/student-loans?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Rewards") {
        return deployUrl + "/rewards?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Travel") {
        return deployUrl + "/travels?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Business") {
        return deployUrl + "/businesses?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Balance Transfer") {
        return deployUrl + "/balance-transfers?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Cash back") {
        return deployUrl + "/shoppings?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Travel cards") {
        return deployUrl + "/ravel-cards?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Hotels cards") {
        return deployUrl + "/hotel-cards?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Student Credit Cards") {
        return deployUrl + "/student-credit-cards?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Forex Brokers") {
        return deployUrl + "/forex-brokers?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Crypto Brokers") {
        return deployUrl + "/crypto-brokers?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Airlines") {
        return deployUrl + "/airlines?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Private jets") {
        return deployUrl + "/private-jets?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Travel sites") {
        return deployUrl + "/travel-sites?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Investing") {
        return deployUrl + "/investings?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Real estate") {
        return deployUrl + "/real-estates?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Books") {
        return deployUrl + "/books?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Tax preparation") {
        return deployUrl + "/tax-preparations?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else if (page === "Gold") {
        return deployUrl + "/golds?filters[country][country][$eq]=" + countryCode + "&filters[name][$containsi]=" + text + "&populate=%2A&sort[0]=createdAt%3Adesc";
    } else {
        return ""
    }
}

export const getTopBrokersUrl = (endpoint: string, countryCode: string) => {
    return deployUrl + `/${endpoint}?filters[country][country][$eq]=${countryCode}&filters[news_page][$eq]=true&populate=%2A&sort[0]=createdAt%3Adesc`;
}

export const getCountryCode = () => {
    return locationURL;
}



export const getSessionStorage = (val: string) => {
    return sessionStorage.getItem(val);
}

export const setSessionStorage = (val: string, data: any) => {
    return sessionStorage.setItem(val, data);
}

export async function getMethod(url: string, header: any) {
    return await axios.get(url, header);
}

export async function postMethod(url: string, body: any, header: any) {
    return await axios.post(url, body, header);
}

export async function putMethod(url: string, body: any, header: any) {
    return await axios.put(url, body, header);
}

export async function deleteMethod(url: string, header: any) {
    return await axios.delete(url, header);
}

export default getHeader;
